<template>
    <!-- 内容管理页面 -->
    <div class="positionT0L0">
        <div v-show="$route.meta.isShow">
            <div class="box">
                <a-button type="primary" v-if="isShowAdd" @click="addAtlas">新增</a-button>
                <a-table :columns="columns" :data-source="tableData" :row-key="record => record.peopleNo" :loading="tableLoading">
                    <span slot="peopleHead" slot-scope="peopleHead, record">
                        <img v-viewer style="width:20px;height: 20px;" class="avatar" slot="record.peopleHead" :src="record.peopleHead"/>
                    </span>
                    <span slot="peopleBust" slot-scope="peopleBust, record">
                        <img v-viewer style="width:20px;height: 20px;" class="avatar" slot="record.peopleBust" :src="record.peopleBust"/>
                    </span>
                    <span slot="peopleContent" slot-scope="peopleContent, record">
                        <span v-if="!record.peopleContent">--</span>
                        <a-tooltip placement="bottom" v-else>
                            <template #title><span>{{ record.peopleContent }}</span></template>
                            <span>{{sizeLength(record.peopleContent)}}</span>
                        </a-tooltip>
                    </span>
                    <span slot="operation" slot-scope="operation, record">
                        <a class="margin_right20" v-if="isShowAdd" @click="editClick(record)">编辑</a>
                        <a-popconfirm v-if="isShowDel" title="确认要删除此人物吗？" ok-text="确定" cancel-text="取消" @confirm="deleteClick(record)">
                            <a>删除</a>
                        </a-popconfirm>
                    </span>
                </a-table>
            </div>
        </div>
        <router-view />
    </div>
</template>

<script>
import { message } from "ant-design-vue";
import { codeFn } from "@/utils/tools";
import {GetAtlasList, DelPeople} from "@/request/api/coursManage";
export default {
    created() {
        this.initFn()
        if (codeFn("/adminv2/addOneAtlasInfo")) {
            this.isShowAdd = true;
        }
        if (codeFn("/adminv2/delPeople")) {
            this.isShowDel = true;
        }
    },

    watch: {
        $route(to) {
            if (to.path === "/coursManage/courseList/RelationShip") {
                this.initFn();
            }
        },
    },

    data() {
        return {
            tableLoading:true,

            isShowAdd:false,
            isShowDel:false,

            count:0,
            pageSize:20,
            pageNo:1,
            // 表格数据
            columns: [
                {
                    title: "姓名",
                    dataIndex: "peopleName",
                    key: "peopleName",
                },
                {
                    title: "家族",
                    dataIndex: "familyName",
                    key: "familyName",
                },
                {
                    title: "身份",
                    dataIndex: "identityName",
                    key: "identityName",
                },
                {
                    title: "年代",
                    dataIndex: "yearName",
                    key: "yearName",
                },
                {
                    title: "坐标",
                    dataIndex: "peoplePosition",
                    key: "peoplePosition",
                },
                {
                    title: "头像",
                    dataIndex: "peopleHead",
                    key: "peopleHead",
                    scopedSlots: { customRender: "peopleHead" },
                },
                {
                    title: "半身图",
                    dataIndex: "peopleBust",
                    key: "peopleBust",
                    scopedSlots: { customRender: "peopleBust" },
                },
                {
                    title: "简介",
                    dataIndex: "peopleContent",
                    key: "peopleContent",
                    scopedSlots: { customRender: "peopleContent" },
                },
                {
                    title: "创建时间",
                    dataIndex: "createTime",
                    key: "createTime",
                },
                {
                    title: "操作",
                    key: "operation",
                    dataIndex: "operation",
                    scopedSlots: { customRender: "operation" },
                }
            ],
            tableData:[],
        };
    },
    methods: {
        // 截取字数
        sizeLength(val){
            if(val.length>10){
                return  val.substring(0,10)+'...'
            }else{
                return val
            }
        },
        // 新增图谱
        addAtlas(){
            this.$router.push(
                `/coursManage/courseList/RelationShip/Atlas/0`
            );
        },

        // 编辑
        editClick(record){
            this.$router.push(
                `/coursManage/courseList/RelationShip/Atlas/${record.peopleNo}`
            );
        },

        // 删除
        deleteClick(record){
            DelPeople({
                peopleNo:record.peopleNo
            }).then(({ code, data }) => {
                if (code == 200) {
                    this.initFn()
                    message.success('删除成功')
                }else{
                    message.error(msg)
                }
            });
        },

        // 初始化加载列表
        initFn(){
            this.tableLoading = true
            GetAtlasList().then(({ code, data }) => {
                if (code == 200) {
                    this.tableLoading = false
                    this.tableData = data
                }else{
                    this.tableLoading = false
                    message.error(msg)
                }
            });
        }
    },
};
</script>

<style lang="less" scoped>

</style>
